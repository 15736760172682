import { isPast, isFuture, format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatDate = (dateString = '', dateFormat = 'MMMM d, y') => {
    if (dateString) {
        const date = parseISO(dateString); // parseISO is used to correctly handle the ISO string.
        return formatInTimeZone(date, 'UTC', dateFormat); // Formats the date in UTC explicitly.
    } else {
        return '';
    }
};

export const formatDateIntnl = (dateString = '', pageLanguage = '') => {
    let dateForm = 'en-EN';
    if (pageLanguage.length) {
        dateForm = `${pageLanguage}-${pageLanguage.toUpperCase()}`;
    }
    if (dateString) {
        const date = parseISO(dateString); // Parse as UTC date
        return new Intl.DateTimeFormat(dateForm, {
            timeZone: 'UTC', // Ensures formatting is based on UTC
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    } else {
        return '';
    }
};

export const dateRange = (start, end, dateFormat = 'MMMM d, yyyy') => {
    const startDate = formatDate(start, dateFormat);
    const endDate = formatDate(end, dateFormat);
    if (startDate === endDate) {
        return startDate;
    } else {
        return `${startDate} - ${endDate}`;
    }
};

export const timeRange = (start, end, timeFormat = 'h:mm a') => {
    const startUTC = parseISO(start);
    const endUTC = parseISO(end);
    return `${formatInTimeZone(startUTC, 'UTC', timeFormat)} - ${formatInTimeZone(endUTC, 'UTC', timeFormat)}`;
};

export const dateRelationEvents = (dateToCheck) => {
    const addDaysToDate = (date, n) => {
        const d = new Date(date);
        d.setDate(d.getDate() + n);
        return d;
    };
    const date = addDaysToDate(dateToCheck, 1);
    if (isPast(date, { timeZone: 'UTC' })) return 'past'; // isPast and isFuture do not have timeZone options but are assumed to handle UTC dates correctly when parsed as such
    if (isFuture(date, { timeZone: 'UTC' })) return 'future';
};

export const dateRelation = (dateToCheck) => {
    const date = parseISO(dateToCheck);
    if (isPast(date, { timeZone: 'UTC' })) return 'past'; // isPast and isFuture do not have timeZone options but are assumed to handle UTC dates correctly when parsed as such
    if (isFuture(date, { timeZone: 'UTC' })) return 'future';
};

export const formatICalDate = (dateString = '', eventType) => {
    const dateFormat = 'yyyy,M,d';
    const dateTimeFormat = 'yyyy,M,d,H,m';
    const date = parseISO(dateString);

    switch (eventType) {
        case 'conference':
            return format(date, dateFormat)
                .split(',')
                .map((x) => Number(x));
        case 'event':
            return format(date, dateTimeFormat)
                .split(',')
                .map((x) => Number(x));
    }
};

export const formatTime = (dateString = '', timeFormat = 'h:mm a') => {
    const date = parseISO(dateString);
    return formatInTimeZone(date, 'UTC', timeFormat);
};

export const parseDate = (dateString) => {
    const date = parseISO(dateString);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return {
        day: date.getUTCDate(),
        month: monthNames[date.getUTCMonth()],
        year: date.getUTCFullYear(),
    };
};
